import React, { useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { collection, getDocs, doc, setDoc, query, where } from 'firebase/firestore';
import { db } from ".././../../firebase";
import Loader from "react-js-loader";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Alert, Button} from 'react-bootstrap';

export const ArchivedTable = ({orgCount}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [orgId, setOrgId] = useState();
  const [show, setShow] = useState(false);
  const columns = [{
    dataField: 'name',
    text: ''
  },{
    dataField: 'phone',
    text: ''
  }, {
    dataField: 'action',
    text: 'ENABLE',
    align: 'right',
    headerAlign: 'right'
  }];
  useEffect(() => {
    getOrganizations();
  },[])

  const getOrganizations = async() => {
    try{
      setIsLoading(true)
      const colRef = collection(db, 'organizations');
      const q = query(colRef, where("isArchived", "==", true));
      const docs = await getDocs(q);
      const org = [];
      orgCount(docs.size);
      docs.forEach(doc => {
         org.push({
           id: doc.id,
           name: doc.data().name,
           phone: doc.data().phone,
           action: <div>
           <PlayCircleOutlineIcon className="mx-3 button-hover" style={{cursor: "pointer"}} onClick={() => {setOrgId(doc.id); setShow(true); return;}}  />
         </div>
         })
      })
      setData(org);

    } catch(e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }
  const onConfirm = () => {
    setShow(false);
    onEnable(orgId);
  }
  const onEnable = async(id) => {
    try{
      setIsLoading(true)
      const colRef = collection(db, 'organizations');
      const docRef = doc(colRef, id);
      await setDoc(docRef, {
        isDisabled: false,
        isArchived: false,
      }, { merge: true });
      getOrganizations();
    } catch(e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <>
      {show && 
        <Alert show={show} variant="dark">
        <Alert.Heading>Are you sure?</Alert.Heading>
        <p>
          You want to enable this organization.
        </p>
        <div className="d-flex justify-content-end">
          <Button onClick={() => setShow(false)} variant="danger" style={{marginRight: 20}}>
            Cancel
          </Button>
          <Button onClick={onConfirm} variant="success">
            Confirm
          </Button>
        </div>
      </Alert>
      }
      {isLoading &&
        <Loader type="spinner-circle" bgColor={"red"} title={"spinner-circle"} color={'#FFFFFF'} size={100} />
      }
      <BootstrapTable keyField='id' data={ data } columns={ columns } bordered={ false } pagination={ paginationFactory() } />
    </>
  );
};
