// import firebase from "firebase/app"
import { initializeApp } from 'firebase/app';
import { getFirestore} from 'firebase/firestore';
import {getAuth} from 'firebase/auth';
import 'firebase/firestore'
// import 'firebase/storage'
// import 'firebase/functions'

require('dotenv').config()

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
}

const app = initializeApp(config)
 
export const auth = getAuth()
export const db = getFirestore(app);
// export const storage = firebase.storage()
// export const functions = firebase.functions()
export default app;