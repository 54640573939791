import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import OrgInfoForm from '../org-info-form';
import DepartmentsForm from '../departments-form';


const AutoPlaySwipeableViews = SwipeableViews


function AddOrgStepper({name, firstName, lastName, phone, setName, setFirstName, setLastName, setPhone, departments, setDepartments}) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 2;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleAdd = () => {
      setDepartments((prevState) => {
          let newDeptsObject = [...prevState]
          newDeptsObject.push({title: "New Department", positions: []})

          return newDeptsObject
      })
  }

  return (
    <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center", flexDirection: "column" }}>
      <div style={{fontWeight: "bold"}}>{!activeStep ? "ORG INFO" : "DEPARTMENTS"}</div>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={activeStep !== maxSteps - 1 ? handleNext : handleAdd}
            // disabled={activeStep === maxSteps - 1}
          >
            {activeStep === maxSteps - 1 ?
            "Add Deparment"
            :
            "Next"
            }
            {activeStep !== maxSteps - 1 ?
            <KeyboardArrowRight />
            : null
            }
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        <OrgInfoForm
            name={name} 
            firstName={firstName} 
            lastName={lastName} 
            phone={phone} 
            setName={setName} 
            setFirstName={setFirstName} 
            setLastName={setLastName} 
            setPhone={setPhone}
        />
        <DepartmentsForm
            departments={departments}
            setDepartments={setDepartments}
        />
      </AutoPlaySwipeableViews>
    </Box>
  );
}

export default AddOrgStepper;