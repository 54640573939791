import React from "react";
import { auth } from "../../firebase";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import logo from "../../assets/phone-list-cropped.png";

import "./side-nav.css";

const useStyles = makeStyles({
  drawer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 350,
    paddingTop: 25,
  },
  navList: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  activeDrawer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#ff0000",
    padding: 25,
    color: "#fff",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#cc0000",
    },
  },
  inactiveDrawer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: 25,
    color: "#333",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    "&:hover": {
      color: "#ff0000",
    },
  },
  navImage: {
    paddingTop: 50,
    paddingBottom: 50,
  },
  navText: {
    fontSize: 24,
  },
  logoutText: {
    textAlign: "center",
    padding: 25,
    "&:hover": {
      cursor: "pointer",
      color: "#ff0000",
    },
  },
});

export const SideNav = (location) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  };

  const handleLogout = () => {
    auth.signOut();
    navigate("/");
  };
  console.log(location);

  return (
    <div className={classes.drawer}>
      <img src={logo} width="200" alt="" className={classes.navImage} />
      <List className={classes.navList}>
        <ListItem button>
          <NavLink
            className={
              usePathname() === "/home/organizations"
                ? classes.activeDrawer
                : classes.inactiveDrawer
            }
            activeClassName="active-link"
            to="/home/organizations"
            exact
          >
            <h3 className={classes.navText}>ORGANIZATIONS</h3>
          </NavLink>
        </ListItem>
        <ListItem button>
          <NavLink
            className={
              usePathname() === "/home/archived"
                ? classes.activeDrawer
                : classes.inactiveDrawer
            }
            activeStyle={{ color: "red" }}
            to="/home/archived"
          >
            <h3 className={classes.navText}>ARCHIVED</h3>
          </NavLink>
        </ListItem>
        <h5
          className={classes.logoutText}
          onClick={() => {
            handleLogout();
          }}
        >
          Log Out
        </h5>
      </List>
    </div>
  );
};
