import React from 'react';
import { Button, Form } from "react-bootstrap";

export default function OrgInfoForm({name, firstName, lastName, phone, setName, setFirstName, setLastName, setPhone}) {
    return (
        <Form>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Organization Name</Form.Label>
              <Form.Control type="text" placeholder="Enter name" value={name} onChange={(event) => setName(event.target.value)}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Organization Admin First Name</Form.Label>
              <Form.Control type="text" placeholder="Enter name" value={firstName} onChange={(event) => setFirstName(event.target.value)}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Organization Admin Last Name</Form.Label>
              <Form.Control type="text" placeholder="Enter name" value={lastName} onChange={(event) => setLastName(event.target.value)}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="phone">
              <Form.Label>Organization Admin Phone No</Form.Label>
              <Form.Control type="tel" placeholder="Enter Phone Number" value={phone} onChange={(event) => setPhone(event.target.value)}/>
            </Form.Group>
        </Form>
    )
}
