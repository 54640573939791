import { Container, Row, Col } from "react-bootstrap";
import Drawer from "@mui/material/Drawer";
import { makeStyles } from "@mui/styles";
import { SideNav } from "../../components/side-nav";
import { Archived } from "../archived";
import { Organizations } from "../organizations";
import { Route, Routes } from "react-router-dom";
import "./home.css";

const useStyles = makeStyles({
  drawerPaper: {
    minHeight: "100vh",
    width: 300,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "6px 0 4px -4px rgba(79,79,79,0.3)",
    overflow: "hidden",
  },
});

export const Home = () => {
  const classes = useStyles();

  return (
    <Container fluid>
      <Row>
        <Col xs={2} className={classes.drawerPaper}>
          <SideNav />
        </Col>
        <Col xsw={10}>
          <div className="dashboardContainer">
            <div id="page-container">
              <Routes>
                <Route path="/organizations" element={<Organizations />} />
                <Route path="/archived" element={<Archived />} />
              </Routes>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
