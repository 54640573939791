import React, { useState, useEffect } from 'react'
import {Accordion} from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import {Form} from 'react-bootstrap';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import "./style.css";
import ButtonWithText from '../../../components/button-with-text';

export default function OrgInfoAccordion({department, deptIdx, savePosition, deletePosition, addPosition, newDept=false, newDeptOnChangePos = null, newDeptDeletePos, newDeptOnChangeDeptName, deleteDepartment, layout, setNewDeptObject, onSaveDeptName}) {
    const [posToEdit, setPosToEdit] = useState(null);
    const [posToDelete, setPosToDelete] = useState(null);
    const [newPosition, setNewPosition] = useState("");
    const [error, setError] = useState({idx: null, text: null});
    const [showDeletePosModal, setShowDeletePosModal] = useState(false);
    const [showDeleteDeptModal, setShowDeleteDeptModal] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [isEditingName, setIsEditiingName] = useState(false);
    const [deleteError, setDeleteError] = useState("");
    const [newDeptName, setNewDeptName] = useState("");

    const onEdit = (e, idx) => {
        e.preventDefault();
        setIsAdding(false);
        setError({idx: null, text: null});
        setPosToEdit(idx);
    }

    const onEndEditing = async (e, idx) => {
        setError({idx: null, text: null});
        setPosToEdit(null);
        
        e.preventDefault();
        if(!newPosition.length) {
            setError({idx: idx, text: "POSITION CANNOT BE BLANK"});
            return
        }

        try {
            await savePosition(deptIdx, idx, newPosition);
        } catch(error) {
            console.log("ERROR SAVING TO DB", error)
            setError({idx: idx, text: "ERROR SAVING POSITON TO DB"});
        }
        return setNewPosition("")
    }
 
    const onDelete = (e, idx) => {
        e.preventDefault();
        setPosToDelete(idx);
        setShowDeletePosModal(!showDeletePosModal);
    }

    const onConfirmDelete = async (e, idx) => {
        e.preventDefault();
        setShowDeletePosModal(!showDeletePosModal);
        setPosToDelete(null);
        
        try {
            console.log(`DELETING ${department.positions[idx]}!!!`);
            await deletePosition(deptIdx, idx);
        } catch(error) {
            console.log("ERROR REMOVING POSITION FROM DB", error)
            setError({idx: idx, text: "ERROR REMOVING POSITION FROM DB"});
        }

        return
    }

    const onAdd = (e) => {
        e.preventDefault();
        console.log("on add pressed")
        setPosToDelete(null);
        setPosToEdit(null);
        setNewPosition("");
        setError({idx: null, text: null});
        setIsAdding(true);
    }

    const onConfirmAdd = async (e) => {
        e.preventDefault();
        console.log("NEW POSITION", newPosition);

        if(newPosition === "") {
            console.log("position cannot be blank")
            setError({idx: "add", text: "POSITION CANNOT BE BLANK"});
            return setIsAdding(false);
        }

        try {
            console.log(`ADDING ${newPosition}!!!`);
            await addPosition(deptIdx, newPosition);
        } catch(error) {
            console.log("ERROR ADDING POSITION TO DB", error)
            setError({idx: "add", text: "ERROR ADDING POSITION TO DB"});
        }

        setIsAdding(false);
        return setNewPosition("")
    }

    const onCancelAdd = (e) => {
        e.preventDefault();
        setNewPosition("");
        setError({idx: null, text: null});
        setIsAdding(false);
    }

    const onDeleteDept = (e) => {
        e.preventDefault();
        if(layout === "modal") {
            setNewDeptObject((prevState) => {
                let newStateArray = []
                prevState.map((dept) => {
                    newStateArray.push(dept)
                });
                newStateArray.splice(deptIdx, 1)
                return newStateArray
            });
        } else {
            setShowDeleteDeptModal(!showDeleteDeptModal);
        }
    }

    const onConfirmDeleteDept = async (e) => {
        e.preventDefault();
        setShowDeleteDeptModal(!showDeleteDeptModal);

        try {
            console.log(`DELETING ${department.title}!!!`);
            await deleteDepartment(deptIdx);
        } catch(error) {
            console.log("ERROR REMOVING DEPARTMENT FROM DB", error)
        }
        return
    }

    const onEditDeptName = () => {
        
        console.log("on edit press")
        setIsEditiingName(true);
    }

    const onChangeDeptName = (value) => {
        if(layout === "modal") {
            setNewDeptObject((prevState) => {
                let newDepartments = [...prevState]
                newDepartments[deptIdx].title = value
    
                return newDepartments
            })
        } else {
            setNewDeptName(value);
        }
    }

    useEffect(() => {
        console.log("new dept name", newDeptName)
    }, [newDeptName])

    const DeletePosModal = () => {
        return (
            <Modal show={showDeletePosModal} onHide={() => setShowDeletePosModal(!showDeletePosModal)}>
              <Modal.Header closeButton>
                <Modal.Title>{`DELETE ${department.positions[posToDelete]} ?`}</Modal.Title>
              </Modal.Header>
              <Modal.Body>THIS CANNOT BE UNDONE!</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDeletePosModal(!showDeletePosModal)}>
                  Close
                </Button>
                <Button variant="primary" onClick={(e) => onConfirmDelete(e, posToDelete)}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
        )
    }

    const DeleteDeptModal = () => {
        return (
            <Modal show={showDeleteDeptModal} onHide={() => setShowDeleteDeptModal(!showDeleteDeptModal)}>
              <Modal.Header closeButton>
                <Modal.Title>{`DELETE ${department.title.toUpperCase()}?`}</Modal.Title>
              </Modal.Header>
              <Modal.Body>THIS CANNOT BE UNDONE!</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDeleteDeptModal(!showDeleteDeptModal)}>
                  Close
                </Button>
                <Button variant="primary" onClick={(e) => onConfirmDeleteDept(e)}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
        )
    }

    return (
        <div style={{width: "100%"}}>
            {newDept && layout !== "modal" &&
            <div className="pos-name-container">
                <Form.Group className="pos-form mb-3" controlId="formBasicEmail">
                  <Form.Control type="email" placeholder="Enter new department name..." onChange={(e) => newDeptOnChangeDeptName(e.target.value)}/>
                </Form.Group>
            </div>
            }
            <Accordion defaultActiveKey={newDept && layout !== "modal" ? "0" : null} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                    {
                    !department.title ? 
                    "NEW DEPARTMENT"
                    : 
                    newDept ? department.title : deptIdx + 1 + ". " + department.title
                    }
                </Accordion.Header>
                <Accordion.Body className="body">
                    <ol className={layout === "modal" ? "pos-list-container-modal-view" : "pos-list-container"}>
                        {
                        newDept ? 
                        <div className="text-primary">
                            {!department.positions.length ? "ADD SOME POSITIONS TO YOUR DEPARTMENT"
                            :
                            department.positions.map((pos, posIdx) => {
                                return (
                                    <li key={pos + posIdx}>
                                        <div className="pos-list-item-alt">
                                            <div className="pos-name-container">
                                                <Form.Group className="pos-form mb-3" controlId="formBasicEmail">
                                                  <Form.Control autoFocus type="email" placeholder="Enter position name..." defaultValue={pos} onChange={(e) => newDeptOnChangePos(posIdx, e.target.value)}/>
                                                </Form.Group>
                                            </div>
                                            <button className="unstyled-button" style={{paddingBottom: 10}} onClick={(e) => newDeptDeletePos(e, posIdx)}>
                                                <DeleteForeverIcon className="text-danger"/>
                                            </button>
                                        </div>
                                    </li>
                                )})
                            }
                        </div>
                        :
                        department.positions.map((pos, posIdx) => {
                            return (
                                <li key={posIdx}>
                                    <Form className="pos-list-item">
                                        <div className="pos-name-container">
                                            {
                                            posIdx === posToEdit ?
                                            <Form.Group className="pos-form mb-3" controlId="formBasicEmail">
                                              {/* <Form.Label>Email address</Form.Label> */}
                                              <Form.Control type="email" placeholder="Enter position name..." defaultValue={pos} onChange={(e) => setNewPosition(e.target.value)}/>
                                            </Form.Group>
                                            :
                                            <p className="pos-text" onClick={(e) => posIdx !== posToEdit ? onEdit(e, posIdx) : null}>{pos}</p>
                                            }
                                        </div>
                                        <div className="pos-actions-container">
                                            {
                                                posIdx === posToEdit ?
                                                <button className="unstyled-button green" type="submit" onClick={(e) => onEndEditing(e, posIdx)}>
                                                    <CheckIcon style={{color: "green"}}/>
                                                </button>
                                                :
                                                <button className="unstyled-button" onClick={(e) => onEdit(e, posIdx)}>
                                                    <EditIcon style={{color: "gray"}}/>
                                                </button>
                                            }
                                            <button className="unstyled-button" onClick={(e) => onDelete(e, posIdx)}>
                                                <DeleteForeverIcon className="text-danger"/>
                                            </button>
                                        </div>
                                    </Form>
                                    {posIdx === error.idx && <div className="text-danger">{error.text}</div>}
                                </li>
                            )
                        })
                        }
                    </ol>
                    <div className="add-container">
                        <div style={{display: "flex", flexDirection: "column", width: 300}}>
                            {layout === "modal" && !isEditingName && <ButtonWithText onAction={onEditDeptName} text="edit" type="edit"/>}
                            {!newDept && !isEditingName && <ButtonWithText onAction={onEditDeptName} text="edit" type="edit"/>}
                            {isEditingName && 
                            <form style={{display: "flex", width: 300, marginBottom: 23}} onSubmit={(e) => {
                                e.preventDefault();
                                console.log("submitting!")
                                if(layout !== "modal") {
                                    console.log("saving new dept name")
                                    onSaveDeptName(deptIdx, newDeptName);
                                } else {
                                    setIsEditiingName(false); 
                                }

                            }}
                            >
                                <Form.Control autoFocus style={{width: 250}} type="email" placeholder="Enter department name..." onChange={(e) => onChangeDeptName(e.target.value)}/>
                                <button style={{marginLeft: 5}} className="unstyled-button green" type="submit" onClick={(e) => {
                                      e.preventDefault();
                                      console.log("submitting!")
                                      if(layout !== "modal") {
                                          console.log("saving new dept name")
                                          onSaveDeptName(deptIdx, newDeptName);
                                      } 
                                        setIsEditiingName(false); 
                                      
                                }}>
                                    <CheckIcon style={{color: "green"}}/>
                                </button>
                            </form>}
                            <ButtonWithText onAction={newDept ? addPosition : onAdd} disabled={isAdding} text="position"/>
                            {layout === "modal" && <ButtonWithText onAction={onDeleteDept} type="delete"/>}
                            {!newDept && <ButtonWithText onAction={onDeleteDept} type="delete"/>}
                        </div>
                        {
                        isAdding &&
                        <Form className="pos-add-form">
                            <div className="pos-add-field">
                                <Form.Group className="pos-form mb-3" controlId="form">
                                  {/* <Form.Label>Email address</Form.Label> */}
                                  <Form.Control placeholder="Enter position name..." onChange={(e) => setNewPosition(e.target.value)}/>
                                </Form.Group>
                            </div>
                            <div className="pos-actions-container">
                                <button className="unstyled-button green" type="submit" onClick={(e) => onConfirmAdd(e)}>
                                    <CheckIcon style={{color: "green"}}/>
                                </button>
                                <button className="unstyled-button" onClick={(e) => onCancelAdd(e)}>
                                    <CancelIcon className="text-danger"/>
                                </button>
                            </div>
                        </Form>
                        }
                        {
                        error.idx === "add" &&
                        <p className="text-danger">{error.text}</p>
                        }
                    </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {DeletePosModal()}
            {DeleteDeptModal()}
        </div>
    )
}
