import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { DataGrid } from '@mui/x-data-grid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function EmployeesTable({employees, setSelectedEmployeeId}) {
    const columns = [
        {
          field: 'photo',
          headerName: '',
          width: 100,
          renderCell: (cellValues) => {
            if(cellValues.row.photo) {
                return (
                  <img
                    src={`${cellValues.row.photo}`}
                    style={{ width: '40px', height: '40px', objectFit: "cover", borderRadius: "100%", cursor: "pointer" }}
                    alt='event'
                  />
                );
            } else {
                return (
                    <AccountCircleIcon
                      style={{ width: '47px', height: '47px', objectFit: "cover", borderRadius: "100%", cursor: "pointer" }}
                    />
                );
            }
          },
          headerAlign: 'center',
          align: 'center',
        },
        {
          field: 'name',
          headerName: 'NAME',
          flex: 1,
          headerAlign: 'left',
          align: 'left',
          renderCell: (cellValues) => {
            return (
              <div style={{cursor: "pointer", color: cellValues.row.name === "MISSING NAME" ? "red" : "black"}}>
                {cellValues.row.name}
              </div>
            );
          },
        },
        {
          field: 'phone',
          headerName: 'PHONE',
          flex: 1,
          headerAlign: 'center',
          align: 'center',
          renderCell: (cellValues) => {
            return (
              <div style={{cursor: "pointer"}}>
                {cellValues.row.phone}
              </div>
            );
          },
        },
        {
          field: 'role',
          headerName: 'ROLE',
          flex: 1,
          headerAlign: 'center',
          align: 'center',
          renderCell: (cellValues) => {
            return (
              <div style={{cursor: "pointer"}}>
                {cellValues.row.role}
              </div>
            );
          },
        },
        {
          field: 'status',
          flex: 1,
          headerName: 'STATUS',
          width: 150,
          renderCell: (cellValues) => {
            return (
              <div style={{color: cellValues.row.status === "ONBOARDED" ? "green" : "red", cursor: "pointer"}}>
                {cellValues.row.status}
              </div>
            );
          },
          headerAlign: 'center',
          align: 'center',
        },
      ];


      const selectEvent = (param) => {
        setSelectedEmployeeId(param.id)
      };

  return (
    <div style={{height: "75vh", width: "70vw"}}>
        <div style={{fontWeight: "bold", marginBottom: 20}}>EMPLOYEES</div>
        {/* <BootstrapTable hover={true} keyField='id' data={ employees } columns={ columns } bordered={ false } pagination={ paginationFactory() }/> */}
        <DataGrid
          rows={employees}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          rowHeight={60}
          sx={{ border: 'none', color: 'black' }}
          onCellClick={selectEvent}
        />
    </div>
  )
}
