import React, { useState } from 'react'
import EmployeesTable from './employees-table'
import EmployeeInfo from './employee-info'

export default function Employees({employees, selectedEmployee, setSelectedEmployee, setSelectedEmployeeId}) {

  return (
    <div>
        {employees.length && !selectedEmployee?.id ? 
        <EmployeesTable 
            employees={employees}
            setSelectedEmployeeId={setSelectedEmployeeId}
        /> 
        : 
        <EmployeeInfo
            setSelectedEmployeeId={setSelectedEmployeeId}
            employee={selectedEmployee}
        />}
    </div>
  )
}
