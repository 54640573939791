import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import "./style.css";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function EmployeeInfo({employee, setSelectedEmployeeId}) {

  return (
    <div>
        <div style={{fontWeight: "bold"}}>EMPOYEE INFO</div>
        <div className="content-container">
            <div className="content-info">
                <Button style={{width: 200, marginBottom: 20, background: "#dc3545"}} variant="contained" onClick={() => setSelectedEmployeeId(null)}>VIEW ALL</Button>
                {employee.photo ?
                    <img className="img" src={employee.photo}/>
                    :
                    <AccountCircleIcon className="img"/>
                }
                <div className="section">
                    <p className="title">EMPLOYEE NAME:&nbsp;&nbsp;</p>
                    <p className="info-text">{employee.name}</p>
                </div>
                <div className="section">
                    <p className="title">PHONE NUMBER:&nbsp;&nbsp;</p>
                    <p className="info-text">{employee.phone}</p>
                </div>
                <div className="section">
                    <p className="title">ROLE:&nbsp;&nbsp;</p>
                    <p className="info-text">{employee.role ?? "Employee"}</p>
                </div>
                {employee.department ?
                <div>

                    <div className="section">
                        <p className="title">DEPARTMENT:&nbsp;&nbsp;</p>
                        <p className="info-text">{employee.department}</p>
                    </div>
                    <div className="section">
                        <p className="title">POSITION:&nbsp;&nbsp;</p>
                        <p className="info-text">{employee.position}</p>
                    </div>
                    <div className="section">
                        <p className="title">STATUS:&nbsp;&nbsp;</p>
                        <p className="info-text" style={{color: "green"}}>ONBOARDED</p>
                    </div>
                </div>
                :
                <div className="section">
                    <p className="title">STATUS:&nbsp;&nbsp;</p>
                    <p className="info-text" style={{color: "red"}}>NOT ONBOARDED</p>
                </div>
                }
            </div>
        </div>

    </div>
  )
}
