import React, { useEffect, useState } from "react";
import {Button, Form, Modal } from "react-bootstrap";
import { collection, doc, addDoc, updateDoc, getDocs, deleteDoc, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import Loader from "react-js-loader";
import AddOrgStepper from '../stepper';
import "./style.css";

const defaultDepartments = [{
  title: "Nursing",
  positions: ["Nurse Manager", "Treatment Nurse", "RN", "LVN", "CMA", "CNA", "Administrator"]
},
{
   title: "Therapy",
   positions: ["PT", "OT", "SLP", "PTA", "COTA", "Tech", "Administrator"]
},
{
    title: "Housekeeping/Laundry",
    positions: ["Housekeeper", "Janitor", "Floor Tech", "Laundry Tech", "Administrator"]
},
{
    title: "Dietary",
    positions: ["Cook", "Asst Cook", "Dietary Aide", "Dishwasher", "Administrator"]
},
{
    title: "Department Manager",
    positions: ["Admission Coordinator", "Marketing Director", "Social Worker", "Director of Nursing", "ADON", "MDS Coordinator", "Business Office Manager", "Asst Administrator", "Maintenance Director", "Housekeeping Supervisor", "Food Service Director", "HR Director", "Administrator"]
}]


export const AddOrganization = ({show, onHide, onAdd, data}) => {

  const [departments, setDepartments] = useState(defaultDepartments);


  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(data !== undefined) {
      setPhone(data.data().adminPhone ? parsePhone(data.data().adminPhone) : '');
      setFirstName(data.data().firstName);
      setLastName(data.data().lastName);
      setName(data.data().name);
    }
    return () => {
      setName('');
      setFirstName('');
      setLastName('');
      setPhone('')
    }
  }, [data])
  const onSave = async() => {
    try{
      setIsLoading(true);
      if(data !== undefined) {
        const colRef = doc(db, 'organizations', data.id);
        // await deleteAdminRecords(phone)
        await updateDoc(colRef,
          {
            name: name,
            adminPhone: phone,
            firstName: firstName,
            lastName: lastName,
          }
        );
      } else {
        const colRef = collection(db, 'organizations');
        await deleteAdminRecords(phone)
        await addDoc(colRef,
          {
            name: name,
            firstName: firstName,
            lastName: lastName,
            adminPhone: phone,
            isDisabled: false,
            isArchived: false,
            departments: departments,
          }
        );
      }
      onAdd();
      onHide();
    } catch(e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  const parsePhone = (phone) => {
    let newPhone = phone.replace(/[^0-9 ]/g, "");
    newPhone = `+1${newPhone}`

    return phone[0] !== "+" ? newPhone : phone
  }

  const deleteAdminRecords = async (phone, isEdit) => {
    const q = query(collection(db, "admin-phone-list"), where("employeeId", "==", parsePhone(phone)));
    const querySnapshot = await getDocs(q);
    
    try {
      querySnapshot.forEach(async (document, idx) => {
        console.log("deleting doc", document)
        await deleteDoc(doc(db, "admin-phone-list", document.id));
      })
    } catch (error) {
      console.log("error deleting docs", error)
    }
  }

  const onCloseModal = () => {
    onHide();
    setDepartments(defaultDepartments);
  }

  return (
  <Modal show={show} onHide={onCloseModal} dialogClassName="modal-width">
      <Modal.Header closeButton>
        <Modal.Title>New Organization</Modal.Title>
      </Modal.Header>
    <Modal.Body>
      {isLoading &&
        <Loader type="spinner-circle" bgColor={"red"} title={"spinner-circle"} color={'#FFFFFF'} size={100} />
      }
      <AddOrgStepper
        name={name} 
        firstName={firstName} 
        lastName={lastName} 
        phone={phone} 
        setName={setName} 
        setFirstName={setFirstName} 
        setLastName={setLastName} 
        setPhone={setPhone}
        departments={departments}
        setDepartments={setDepartments}
      />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={onCloseModal}>Cancel</Button>
      <Button variant="success" onClick={onSave}>Save</Button>
    </Modal.Footer>
  </Modal>
);
};
