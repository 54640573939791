import React, { useEffect, useState } from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    NavLink,
    useNavigate,
    createSearchParams,
    useParams,
  } from 'react-router-dom';
import { collection, setDoc, doc, query, where, getDoc, updateDoc, getDocs } from 'firebase/firestore';
import { db } from "../../firebase";
import { SideNav } from "../../components/side-nav"
import "./style.css";
import OrgInfoAccordion from './department-accordion';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ButtonWithText from "../../components/button-with-text";
import NewDepartment from './new-department';
import Employees from '../../components/employees';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function OrganizationInfo() {
    const params = useParams();
    const docRef = doc(db, "organizations", params.id);

    const tabs = ["ORG INFO", "DEPARTMENTS", "EMPLOYEES"]

    const [orgInfo, setOrgInfo] = useState({});
    const [employees, setEmployees] = useState([]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
    const [selectedEmployee, setSelectedEmployee] = useState(null)
    const [currentTab, setCurrentTab] = useState(0);
    const [isAdding, setIsAdding] = useState(false);
    const [newDeptObject, setNewDeptObject] = useState({
        title: "",
        positions: []
    });
    const [error, setError] = useState("");

    useEffect(() => {
        getOrgInfo();
        getEmployees();
    },[]);

    useEffect(() => {
        getEmp(selectedEmployeeId)
    },[selectedEmployeeId])


    const getOrgInfo = async () => {
        setNewDeptObject({
            title: "",
            positions: []
        })
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          setOrgInfo(docSnap.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
    };

    const getEmp = (id) => {
        setSelectedEmployee(employees.find((emp) => emp.id === id))
    }

    const getEmployees = async () => {
        setEmployees([]);
        const newEmployees = []

        const collectionPath = collection(db, `organizations/${params.id}/employees`)
        const collectionRef = await getDocs(collectionPath)

        console.log("COLLECTION REF", collectionRef)

        collectionRef.forEach((employee, idx) => {
            newEmployees.push({
                id: employee.id,
                name: (!employee.data().first_name || !employee.data().last_name) ? "MISSING NAME" : employee.data().first_name + " " + employee.data().last_name,
                phone: formatPhoneNumber(employee.data().phone.slice(2)),
                role: employee.data().role ?? "Employee",
                department: employee.data().department,
                position: employee.data().position,
                photo: employee.data().photo,
                // photoElement: employee.data().photo ? 
                // <img style={{width: 40, height: 40, borderRadius: "100%", objectFit: "cover", background: "light-gray"}} onClick={() => setSelectedEmployeeId(employee.id)} src={employee.data().photo}/>
                // :
                // <AccountCircleIcon style={{width: 45, height: 45, borderRadius: "100%"}} onClick={() => setSelectedEmployeeId(employee.id)}/>,
                status: employee.data().department ? "ONBOARDED" : "NOT ONBOARDED"
            })
        })
        setEmployees(newEmployees);
    }

    const formatPhoneNumber = (phone) => {
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }

    const savePosition = async (deptIdx, posIdx, newPosition) => {
        const newDepartments = orgInfo.departments
        newDepartments[deptIdx].positions[posIdx] = newPosition;

        try{
            await updateDoc(docRef, {
                departments: newDepartments
            });
        } catch(error) {
            console.log("error updating position")
        }

        return getOrgInfo();
    };

    const deletePosition = async (deptIdx, posIdx) => {
        const newDepartments = orgInfo.departments
        newDepartments[deptIdx].positions.splice(posIdx, 1);

        try{
            await updateDoc(docRef, {
                departments: newDepartments
            });
        } catch(error) {
            console.log("error deleting position")
        }
        return getOrgInfo();
    };

    const addPosition = async (deptIdx, newPosition) => {
        const newDepartments = orgInfo.departments
        newDepartments[deptIdx].positions.push(newPosition);

        try{
            await updateDoc(docRef, {
                departments: newDepartments
            });
        } catch(error) {
            console.log("error deleting position")
        }
        return getOrgInfo();
    }

    const onAdd = (e) => {
        e.preventDefault();
        setIsAdding(true);
        console.log("adding department");
    }

    const onSaveDepartment = async () => {
        if(!newDeptObject.title) {
            setError("new department must have a name!");
            return;
        }
        if(newDeptObject.positions.includes('')) {
            setError("one of your positions is missing a name!");
            return;
        }
        if(!newDeptObject.positions.length) {
            setError("new department must have at least 1 position!");
            return;
        }

        const newDepartments = orgInfo.departments
        newDepartments.push(newDeptObject);

        try{
            await updateDoc(docRef, {
                departments: newDepartments
            });
        } catch(error) {
            setError("error updating db");
            console.log("error updating db");
        }
        
        setIsAdding(false);
        return getOrgInfo();
    }

    const onCancelDepartment = () => {
        setError("")
        setNewDeptObject({
            title: "",
            positions: []
        })
        setIsAdding(false);
    }

    const deleteDepartment = async (idx) => {
        const newDepartments = orgInfo.departments
        newDepartments.splice(idx, 1);
        try{
            await updateDoc(docRef, {
                departments: newDepartments
            });
        } catch(error) {
            console.log("error updating db");
            setError("ERROR DELETING FROM THE DB");
            return
        }
        return getOrgInfo();
    }

    const onSaveDeptName = async (deptIdx, value) => {
        const newDepartments = orgInfo.departments
        newDepartments[deptIdx].title = value

        try{
            await updateDoc(docRef, {
                departments: newDepartments
            });
        } catch(error) {
            console.log("error updating department name position")
        }
        return getOrgInfo();
    }

    const renderOrgInfo = () => {
        return (
            <div className="content-container">
                <div className="content-info">
                    <div className="section">
                        <p className="title">PHONE NUMBER:&nbsp;&nbsp;</p>
                        <p className="info-text">{orgInfo?.phone?.slice(2)}</p>
                    </div>
                    <div className="section">
                        <p className="title">OWNER NAME:&nbsp;&nbsp;</p>
                        <p className="info-text">{orgInfo.firstName} {orgInfo.lastName}</p>
                    </div>
                    <div className="section">
                        <p className="title">CHECKIN FREQUENCY:&nbsp;&nbsp;</p>
                        <p className="info-text">{orgInfo.checkinFrequency}</p>
                    </div>
                </div>
            </div>
        )
    }

    const renderDepartments = () => {
        return (
            <div className="content-container">
                <div className="departments-container">
                <div className="dept-header">
                    {
                        !isAdding ?
                            <ButtonWithText onAction={onAdd} type="add"/>
                        :
                        <div>
                            <div style={{display: "flex", width: 500, justifyContent: "space-between"}}>
                                <ButtonWithText onAction={onSaveDepartment} type="save"/>
                                <ButtonWithText onAction={onCancelDepartment} type="cancel"/>
                            </div>
                            {error && <div className="text-danger">{error.toLocaleUpperCase()}</div>}
                        </div>
                    }
                    <div style={{display: "flex", justifyContent: "space-between", fontWeight: "bold", paddingRight: "42%", height: 30}}>
                        <p style={{color: "gray"}}>DEPARTMENT NAME</p>
                        <p style={{color: "gray"}}>ACTIONS</p>
                    </div>
                    {isAdding && 
                    <NewDepartment 
                        onSaveDepartment={onSaveDepartment} 
                        onCancelDepartment={onCancelDepartment}
                        newDeptObject={newDeptObject}
                        setNewDeptObject={setNewDeptObject}
                    />
                    }
                </div>
                    {orgInfo.departments.map((dept, idx) => {
                        return (
                            <OrgInfoAccordion 
                                key={dept + idx} 
                                department={dept} 
                                deptIdx={idx} 
                                savePosition={savePosition}
                                deletePosition={deletePosition}    
                                addPosition={addPosition}
                                deleteDepartment={deleteDepartment}
                                onSaveDeptName={onSaveDeptName}
                            />
                        )
                    })}
                </div>
             </div>
        )
    }

    const renderEmployees = () => {
        return (
            <div className="content-container">
                <Employees 
                    employees={employees} 
                    selectedEmployee={selectedEmployee} 
                    setSelectedEmployee={setSelectedEmployee}
                    setSelectedEmployeeId={setSelectedEmployeeId}
                />
            </div>
        )
    }

    const departmentHeader = () => {
        return (
            <div className="dept-header">

            </div>
        )
    }

    return (
        <div style={{display: "flex"}}>
            <div>
                <SideNav/>
            </div>
            <div className="main-container">
                <div className="header text-danger">
                    <h2 className="header-title">
                        {`Manage: ${orgInfo?.name}`}
                    </h2>
                </div>
                <div className="tab-container">
                    {
                        tabs.map((tab, idx) => {
                            return (
                                <button key={idx} className={`${currentTab === idx ? "tab-selected" : "tab"} text-danger`} onClick={() => setCurrentTab(idx)}>
                                    {tab}
                                </button>
                            )
                        })
                    }
                </div>
                {
                  currentTab === 0 ? renderOrgInfo()
                  : currentTab === 1 ? renderDepartments()
                  : currentTab === 2 ? renderEmployees()
                  : null
                }
            </div>
        </div>
    )
}
