import { Row, Col } from "react-bootstrap";
import { OrganizationsTable } from "./table";
import {AddOrganization} from "./add-organization";
import "./organizations.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  NavLink,
  useNavigate,
  createSearchParams,
} from 'react-router-dom';

export const Organizations = () => {
  const [show, setShow] = useState(false);
  const [editData, setEditData] = useState();
  const [refresh, setRefresh] = useState();
  const [count, setCount] = useState(0);

  const navigate = useNavigate();

  console.log('show', show);
  const onHide = () => {
    setShow(false);
  }

  const onEdit = (data) => {
    console.log("ORG DATA", data.id)

    navigate({
      pathname: `/organizations/org/id=${data.id}`,
      // search: `?id=${data.id}`
    });
    setEditData(data);
    setShow(true);
  }

  const getCount = (n) => {
    setCount(n);
  }

  return (
    <Col className="d-flex flex-column justify-content-start pt-4 sideContainer">
      <Row className="w-100">
        <div className="d-flex align-items-center justify-content-between text-danger">
          <h2>YOU HAVE {count} ORGANIZATIONS</h2>
          <AddCircleIcon sx={{ padding: 0, fontSize: 58, cursor: "pointer"}} className='button-hover' onClick={() => { setShow(true); setEditData(undefined); return;}}/>
        </div>
      </Row>
      <Row className="w-100">
        <OrganizationsTable refresh={refresh} onEdit={onEdit} orgCount={getCount}/>
        <AddOrganization show={show} onHide={onHide} onAdd={() => setRefresh(new Date())} data={editData}/>
      </Row>
    </Col>
  );
};
