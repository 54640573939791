import { Routes, Route } from 'react-router';
import {Home} from './routes/home';
import {Login} from './routes/login';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from './Auth';
import TermsOfService from './routes/termsofservice';
import PrivacyPolicy from './routes/privacypolicy';
import Contact from './routes/contact';
import OrganizationInfo from './routes/org-info';


export const Router = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route exact path='/' element={<Login />} />
        <Route path='/home/*' element={<PrivateRoute><Home /></PrivateRoute>} />
        <Route path='/termsofservice' element={<TermsOfService/>}/>
        <Route path='privacypolicy' element={<PrivacyPolicy/>}/>
        <Route path='contact' element={<Contact/>}/>
        <Route path='/organizations/org/id=:id' element={<OrganizationInfo/>}/>
      </Routes>
    </AuthProvider>
  );
};
