import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./Router";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import "./App.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ff0000",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div className="Site">
          <div className="Site-content">
            <Router />
          </div>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
