import React from 'react'
import styles from './styles'

export default function Contact() {
    return (
        <>
            <h1 
              style={{ 
                  color: '#cc0004', 
                  textAlign: 'center', 
                  marginTop: 50}}
             >
              PHONE LIST
            </h1>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                paddingLeft: 25,
                paddingRight: 25,
                justifyContent: 'center',
                background: '#fff',
                flexDirection: 'column',
              }}
            >
                <p style={styles.pageTitle}>CONTACT US</p>

                <p style={styles.text}>
                    For support or questions, please contact us at phonelist@gmail.com
                </p>
            </div>
        </>
    )
}
