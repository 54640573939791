import React, { useState, useEffect } from 'react';
import OrgInfoAccordion from '../department-accordion';

export default function NewDepartment({onSaveDepartment, onCancelDepartment, newDeptIdx, newDeptObject, setNewDeptObject, layout, departments, setDepartments}) {
    

    useEffect(() => {
        console.log("NEW DEPARTMENT STATE", newDeptObject);
    },[newDeptObject])

    useEffect(() => {
        console.log("DEPARTMENTS", departments)
    },[departments])

    const onCancel = (e) => {
        e.preventDefault();
        onCancelDepartment();
    }
    
    const onSave = (e) => {
        e.preventDefault();
        onSaveDepartment();
    }

    const addPosition = (e) => {
        e.preventDefault();
        if(layout === "modal") {
            setNewDeptObject((prevState) => {
                let newStateArray = [];
                prevState.map((dept) => {
                    newStateArray.push(dept)
                })
                newStateArray[newDeptIdx].positions.push("");
    
                return [...newStateArray]
            })
        } else {
            setNewDeptObject((prevState) => {
                console.log("prev state", prevState);
                let positions = [...prevState.positions];
                console.log("POSITIONS", positions)
                positions.push("");
    
                return {
                    title: prevState.title,
                    positions: positions,
                };
            })
        }
    }

    const onChangePosition = (idx, value) => {
        if(layout === "modal") {
            setNewDeptObject((prevState) => {
                let newStateArray = []
                prevState.map((dept) => {
                    newStateArray.push(dept)
                });
                newStateArray[newDeptIdx].positions[idx] = value
                return newStateArray
            });
        } else {
            setNewDeptObject((prevState) => {
                let positions = [...prevState.positions];
                positions[idx] = value;
    
                return {
                    title: prevState.title,
                    positions: positions,
                };
            });
        }
    };

    const onChangeDeptName = (value) => {
        setNewDeptObject((prevState) => {
            const title = value
            return {
                title: title,
                positions: prevState.positions,
            };
        });
    };

    const deletePosition = (e, idx) => {
        console.log("idx to delete", idx)
        e.preventDefault();

        if(layout === "modal") {
            setDepartments((prevState) => {
                let newDepartments = [...prevState]
                newDepartments[newDeptIdx].positions.splice(idx, 1)
                return newDepartments
            })  
        } else {
            setNewDeptObject((prevState) => {
                return {
                    title: prevState.title,
                    positions: [...prevState.positions.slice(0, idx), ...prevState.positions.slice(idx + 1)],
                };
            })
        }

    }

    return (
        <div style={{width: "100%", height: "300", marginBottom: 10, display: "flex", flexDirection: "column"}}>
            <OrgInfoAccordion 
                newDept={true}
                deptIdx={newDeptIdx}
                department={newDeptObject}
                addPosition={addPosition}
                newDeptOnChangePos={onChangePosition}
                newDeptDeletePos={deletePosition}
                newDeptOnChangeDeptName={onChangeDeptName}
                setNewDeptObject={setNewDeptObject}
                layout={layout}
            />
        </div>
    )
}
