import React, { useEffect, useState } from 'react';
import { auth } from './firebase';
import {onAuthStateChanged} from 'firebase/auth';
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  
  const [currentUser, setCurrentUser] = useState();
  const [pending, setPending] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setPending(false); 
    })
  }, []);

  if (pending) {
    return <>Loading...</>;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
