import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import React, { useEffect, useState } from "react";

import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { collection, setDoc, doc, query, where, getDocs } from 'firebase/firestore';
import { db } from "../../../firebase";
import Loader from "react-js-loader";
import {Alert, Button} from 'react-bootstrap';

export const OrganizationsTable = ({refresh, onEdit, orgCount}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [orgId, setOrgId] = useState();
  const [status, setStatus] = useState(false);
  const [actionType, setActionType] = useState();
  const columns = [{
    dataField: 'name',
    text: '',
    style: {cursor: "pointer"},
  },{
    dataField: 'phone',
    text: '',
    style: {cursor: "pointer"},
  },{
    dataField: 'action',
    text: 'DISABLE/ARCHIVE',
    align: 'right',
    headerAlign: 'right',
    classes: 'mx-3 button-hover cursor-pointer',
  }];
  useEffect(() => {
    getOrganizations();
  },[refresh])

  const getOrganizations = async() => {
    try{
      setIsLoading(true)
      const colRef = collection(db, 'organizations');
      const q = query(colRef, where("isArchived", "==", false));
      const docs = await getDocs(q);
      orgCount(docs.size);
      const org = [];
      docs.forEach(doc => {
         org.push({
           id: doc.id,
           name: <div onClick={() => onEdit(doc)}>{doc.data().name}</div>,
           phone: <div onClick={() => onEdit(doc)}>{doc.data().phone}</div>,
           action: <div>
           {doc.data().isDisabled ? 
           <PlayCircleOutlineIcon className="mx-3 button-hover" style={{cursor: "pointer"}} onClick={() => {setOrgId(doc.id); setStatus(doc.data().isDisabled); setShow(true); setActionType('disable'); return;}} />
           : <PauseCircleOutlineIcon className="mx-3 button-hover" style={{cursor: "pointer"}} onClick={() => {setOrgId(doc.id); setStatus(doc.data().isDisabled); setShow(true); setActionType('disable'); return;}} />
           } 
           <FolderOpenIcon className="mx-3 button-hover" style={{cursor: "pointer"}} onClick={() => {setOrgId(doc.id); setShow(true); setActionType('archive'); return;}} />
         </div>
         })
      })
      setData(org);

    } catch(e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  const onConfirm = () => {
    setShow(false);
    onDisable(orgId);
  }

  const onDisable = async(id) => {
    try{
      setIsLoading(true)
      const colRef = collection(db, 'organizations');
      const docRef = doc(colRef, id);
      await setDoc(docRef, {
        isDisabled: !status,
        isArchived: actionType === 'archive' ? true : false,
      }, { merge: true });
      getOrganizations();
    } catch(e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }

  }

  
  return (
    <>
      {show && 
        <Alert show={show} variant="dark">
        <Alert.Heading>Are you sure?</Alert.Heading>
        <p>
          You want to {actionType === 'disable' ? status ? 'enable' : 'disable' : actionType} this organization.
        </p>
        <div className="d-flex justify-content-end">
          <Button onClick={() => setShow(false)} variant="danger" style={{marginRight: 20}}>
            Cancel
          </Button>
          <Button onClick={onConfirm} variant="success">
            Confirm
          </Button>
        </div>
      </Alert>
      }
      {isLoading &&
        <Loader type="spinner-circle" bgColor={"red"} title={"spinner-circle"} color={'#FFFFFF'} size={100} />
      }
      <BootstrapTable hover={true} keyField='id' data={ data } columns={ columns } bordered={ false } pagination={ paginationFactory() }/>
    </>
  );
};
