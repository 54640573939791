import React from 'react';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import "./style.css";

export default function ButtonWithText({onAction, disabled=false, text="department", type="add"}) {
    return (
        <button className={`unstyled-button ${disabled ? "disabled" : "button"} ${"bg-" + type}`} disabled={disabled} onClick={(e) => onAction(e)}>
            <div className="button-content">
                {type === "add" ?
                    <AddCircleIcon sx={{ padding: 0, fontSize: 48}} className='button-hover'/>
                : type === "save" ?
                    <CheckCircleIcon sx={{ padding: 0, fontSize: 48}} className='button-hover'/>
                : type === "cancel" ?
                    <CancelIcon sx={{ padding: 0, fontSize: 48}} className='button-hover'/>
                : type === "delete" ?
                    <CancelIcon sx={{ padding: 0, fontSize: 48}} className='button-hover'/>
                : type === "edit" ?
                    <div style={{width: 42, height: 42, borderRadius: 100, backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 5, marginRight: 5}}>
                        <EditIcon sx={{ padding: 0, fontSize: 30}} className='button-hover text-danger'/>
                    </div>
                : null
                }
                <p style={{paddingTop: "14px", marginLeft: "2px", fontSize: type === "delete" ? "15px" : type === "edit" ? "13px" : "16px", fontWeight: "bold"}}>
                    {`${type.toLocaleUpperCase()} ${type === "add" || type === "delete" ? text.toLocaleUpperCase() : type === "edit" ? "DEPARTMENT NAME" : ""}`}
                </p>
            </div>
        </button>
    )
}
