import * as React from 'react';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from 'react';
import { Container, Row, Col, Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/phone-list-cropped.png'
import Button from '@mui/material/Button';

import './login.css';

export const Login = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();


  async function handleSubmit(e) {
    e.preventDefault();
    try{
      console.log('test', email, password);
      // e.preventDefault();
      //await signOut(auth);
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/home/organizations');
    } catch(e) {
      console.log(e);
    }
  }
  return (
    <div>

    <Container  className="d-flex align-items-center">
      <Row id='page-container'>
        <Col className="d-flex flex-column align-items-center pt-5y">
          <Row>
            <Col>
              <img src={logo} width="500" alt='' />
            </Col>
          </Row>
          <Row className=''>
            <Col>
              <p className='login-title'>LOGIN</p>
            </Col>
          </Row>

          <Row className="w-50">
            <Form className="d-flex flex-column align-items-center w-4">
              <Form.Group className="mb-4 w-100" controlId="formBasicEmail">
                {/* <Form.Label>Email address</Form.Label> */}
                <Form.Control type="email" placeholder="Enter email" onChange={(event) => setEmail(event.target.value)} />
                {/* <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text> */}
              </Form.Group>

              <Form.Group className="mb-4 w-100" controlId="formBasicPassword">
                {/* <Form.Label>Password</Form.Label> */}
                <Form.Control type="password" placeholder="Password" onChange={(event) => setPassword(event.target.value)} />
              </Form.Group>
              <Button variant="contained" type="submit" size='medium' onClick={(e) => handleSubmit(e)} sx={{ pr:6, pl: 6, borderRadius: 2 }}>Log In</Button>
            </Form>
          </Row>
        </Col>
      </Row>
      <Row>
        
      </Row>
    </Container>
      <div style={{display: "flex", justifyContent: "center", paddingTop: 75}}>Version 1.0</div>
    </div>
  );
}
