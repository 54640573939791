import { Col, Row } from 'react-bootstrap';
import { ArchivedTable } from './table/index'
import { useState } from "react";

export const Archived = () => {
  const [count, setCount] = useState(0);
  const getCount = (n) => {
    setCount(n);
  }
  return(
    <Col className="d-flex flex-column justify-content-start pt-4 sideContainer">
      <Row className="w-100">
        <div className="d-flex align-items-center justify-content-between text-danger">
          <h2>YOU HAVE {count} ARCHIVED ORGANIZATIONS</h2>
        </div>
      </Row>
      <Row className="w-100">
        <ArchivedTable orgCount={getCount}/>
      </Row>
    </Col>
  )
}