import React from 'react';
import NewDepartment from '../../org-info/new-department';

export default function DepartmentsForm({departments, setDepartments}) {
    return (
        <div>
            {departments.map((dept, idx) => {
                return (
                    <NewDepartment
                        key={dept + idx}
                        newDeptObject={dept}
                        newDeptIdx={idx}
                        layout="modal"
                        setNewDeptObject={setDepartments}
                        departments={departments}
                        setDepartments={setDepartments}
                    />
                )
            })}
        </div>
    )
}
